.skillbar {
    border-radius: 15px !important;
    margin: 25px 0;
}

.skillbar-title {
    border-radius: 15px 0 0 15px !important;
}

.skillbar-bar {
    border-radius: 0 15px 15px 0 !important;
}

.skillbar-percent {
    color: #FF651C !important;
}
