/* Customize Carousel Dots */
.slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
    bottom: -30px !important;
}

.slick-dots li {
    margin: 0 0.25rem;
}

.slick-dots li button {
    width: 2px !important;
    height: 2px !important;
    padding: 0;
    border-radius: 100%;
    border: 2px solid #FF651C !important;
    text-indent: -9999px;
}

.slick-dots li.slick-active button {
    background-color: #FF651C;
}