.side-line {
    height: 8px;
    width: 8px;
    margin: 0 10px;
    background-color: #FF651C;
    transform: rotate(45deg);
}

.middle-line {
    width: 180px;
    height: 4px;
    background-color: #FF651C;
    border-radius: 4px;
    position: relative;
}

.inner-circle {
    height: 10px;
    width: 10px;
    background-color: #212121;
    transform: rotate(45deg);
    border: 1px solid #FF651C;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    animation: left-right-moving 6s infinite;
    animation-timing-function: linear;
}

@keyframes left-right-moving {

    0%,
    100% {
        left: 0%;
    }

    50% {
        left: 97%;
    }
}